import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SearchIcon } from "../assets/icons";
import {
  CancleIcon,
  FilterIcon,
  NextPageIcon,
  PreviousPageIcon,
} from "../assets/icons/webinar";
import CustomButton from "../components/CustomButton";
import DemoModal from "../components/DemoModal";
import Filters from "../components/WebinarLp/Filters";
import HeroCard from "../components/WebinarLp/HeroCard";
import WebinarCard from "../components/WebinarLp/WebinarCard";
import Layout from "../components/layout";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import HeroSection from "../components/pricing_page/HeroSection";
import SEO from "../components/seo";
import Popup from "../partials/Popup";
import { checkFutureTime, sanatizeSearchText } from "../utilities/helpers";
import "../styles/page/webinars.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Webinars = ({ location, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState("On-demand");
  const [pageNumber, setPageNumber] = useState(1);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const webinarsData = data?.prismicWebinarPage?.data || {};
  const webinarsForbannerSwiper = data?.allPrismicWebinarDetail?.nodes
    ?.filter((it) => it.data?.featured_webinar)
    ?.sort((a, b) => (checkFutureTime(a.data?.start_time) ? -1 : 1));
  const allWebinars = data?.allPrismicWebinarDetail?.nodes?.filter(
    (w) => !checkFutureTime(w.data?.start_time)
  );
  const upcomingWebinars = data?.allPrismicWebinarDetail?.nodes?.filter((w) =>
    checkFutureTime(w.data?.start_time)
  );

  // Making the dynamic tags off on the request of Will
  // const tags = data?.allPrismicWebinarDetail?.distinct || [];
  const tags = ["On-demand", "Upcoming"];

  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const cardsPerPage = 6;
  const previousPageCondition = pageNumber - 1 > 0;
  const nextPageCondition =
    allWebinars?.length >= pageNumber * cardsPerPage + 1;

  const handleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (!isTablet) {
      handleApplyFilter(option);
    }
  };

  const handleReset = () => {
    setSelectedOption("");
    setIsFilterModalOpen(false);
  };

  const handleApplyFilter = () => {
    setPageNumber(1);
    setIsFilterModalOpen(false);
  };

  const handleNextPage = () => {
    if (nextPageCondition) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (previousPageCondition) {
      setPageNumber(pageNumber - 1);
    }
  };

  const regex = new RegExp(sanatizeSearchText(searchText), "iu");
  const webinarsToFilter =
    selectedOption === "On-demand" ? allWebinars : upcomingWebinars;
  const _otherWebinars = webinarsToFilter
    // .filter((x) => !selectedOption || x.tags.includes(selectedOption))
    ?.filter((item) => item?.data?.page_heading?.text?.match(regex)?.length);

  return (
    <Layout location={location} theme="newDefault">
      <article>
        <section className="relative overflow-hidden font-manrope tracking-tighter_3">
          <article className="relative px-4 pt-32 mx-auto sm:px-12 lg:px-16 max-w-7xl md:pt-44">
            <HeroSection
              title={webinarsData?.page_heading?.html}
              description={webinarsData?.page_description?.text}
              descriptionClass="max-w-full font-inter sm:text-lg text-sm"
              headingClass="text-2.5xl sm:text-4.5xl sm:mb-8 mb-5"
              mainClass="px-0"
            />
            <section className="-mt-2 sm:-mt-10">
              <Swiper
                spaceBetween={30}
                loop
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {webinarsForbannerSwiper?.map((_wb) => {
                  return (
                    <SwiperSlide>
                      <HeroCard
                        label={
                          checkFutureTime(_wb?.data?.start_time)
                            ? "Upcoming webinar"
                            : "Featured webinar"
                        }
                        heading={_wb?.data?.page_heading?.text}
                        date={_wb?.data?.end_time}
                        hide_date={_wb?.data?.hide_date}
                        time={_wb?.data?.start_time}
                        description={_wb?.data?.page_description?.text}
                        cta_link={`/webinar/${_wb?.uid}/`}
                        speakers={_wb.data}
                        mainClass="bg_hero"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>

            <section className="flex flex-col pb-10 mt-5 sm:pb-16 lg:pt-16 sm:pt-10 lg:flex-row gap-x-24">
              <div className="flex flex-col lg:gap-y-9">
                <div className="flex items-center sm:gap-x-7.5 gap-x-5">
                  <div className="relative flex items-center w-full">
                    <input
                      placeholder="Search..."
                      className="w-full h-10 py-3 rounded-lg lg:w-48 search_input bg-gray-1500"
                      type="text"
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                      value={searchText}
                    />
                    <SearchIcon />
                  </div>
                  <div
                    className="cursor-pointer lg:hidden"
                    onClick={handleFilterModal}
                  >
                    <FilterIcon fill="fillGray" />
                  </div>
                </div>
                <div className="hidden lg:block">
                  <Filters
                    title={"Categories"}
                    options={tags}
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                  />
                </div>
                {selectedOption && isTablet && (
                  <div className="text-purple-1500 capitalize px-3 rounded-full max-w-max flex bg-[#EEEBFF] items-center py-1.5 sm:mt-4 mt-2.5 gap-x-1.5 text-inter text-sm tracking-normal font-medium">
                    <div className="cursor-pointer" onClick={handleReset}>
                      <CancleIcon />
                    </div>
                    <div>{selectedOption}</div>
                  </div>
                )}
              </div>
              <div id="WebinarCards">
                <h2 className="text-2.5xl sm:mb-6 mb-5 font-bold text-purple-1100 lg:mt-0 sm:mt-9 mt-8">
                  {selectedOption} webinars
                </h2>
                <div className="grid grid-cols-1 grid-rows-2 gap-5 xl:grid-cols-3 sm:grid-cols-2">
                  {_otherWebinars
                    ?.slice(
                      (pageNumber - 1) * cardsPerPage,
                      cardsPerPage * pageNumber
                    )
                    ?.map((card, index) => {
                      return (
                        <WebinarCard
                          index={index}
                          cardTime={card?.data?.video_time}
                          startTime={card?.data?.start_time}
                          cardHeading={card?.data?.page_heading?.text}
                          thumbnail={card?.data?.thumbnail}
                          tags={card?.tags}
                          slug={card?.uid}
                          searchText={searchText}
                        />
                      );
                    })}
                </div>
                {_otherWebinars.length > 0 ? (
                  <section className="flex items-center justify-center gap-5 sm:mt-14 mt-7">
                    <a
                      href={isMobile && "#WebinarCards"}
                      className={
                        previousPageCondition
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }
                      onClick={handlePreviousPage}
                    >
                      <PreviousPageIcon />
                    </a>

                    {[
                      ...Array(
                        Math.ceil(_otherWebinars?.length / cardsPerPage)
                      ).keys(),
                    ].map((_, index) => {
                      return (
                        <a
                          href={isMobile && "#WebinarCards"}
                          className={`cursor-pointer px-4 py-2 text-base font-semibold text-center rounded-md ${
                            pageNumber === index + 1 &&
                            "bg-purple-1500 text-white"
                          }`}
                          onClick={() => setPageNumber(index + 1)}
                        >
                          {index + 1}
                        </a>
                      );
                    })}

                    <a
                      href={isMobile && "#WebinarCards"}
                      className={
                        nextPageCondition
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }
                      onClick={handleNextPage}
                    >
                      <NextPageIcon />
                    </a>
                  </section>
                ) : (
                  <h1 className="text-sm text-center text-gray-600 tracking-tight_1 sm:tracking-tight font-inter sm:text-lg">
                    No Webinar Found!
                  </h1>
                )}
              </div>
            </section>

            <AnnotateFooter
              heading={webinarsData?.cta_heading?.text}
              description={webinarsData?.cta_description?.text}
              openModal={handleModal}
              imageBG={true}
            />
          </article>
        </section>

        <DemoModal
          isOpen={isOpen}
          closeModal={handleModal}
          location={location}
        />
        <Popup isOpen={isFilterModalOpen} closeModal={handleFilterModal}>
          <section className="flex flex-col px-5 py-8 bg-white sm:px-10 gap-y-6 font-manrope tracking-tighter_3">
            <div className="flex items-center gap-x-2">
              <FilterIcon fill="fillPurple" />
              <div className="text-2xl font-semibold text-purple-1100">
                Filters
              </div>
            </div>
            <Filters
              title="Categories"
              options={tags}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
            <div className="grid grid-cols-2 gap-x-5">
              <CustomButton
                onClick={handleReset}
                text="Reset"
                buttonClass="text-base font-manrope text-gray-2500 bg-gray-2800 font-semibold lg:px-4 lg:py-2 rounded-full border border-gray-2200 hover:border-gray-2500 focus:border-gray-2500"
                isFullWidth
              />
              <div className="hidden sm:block">
                <CustomButton
                  text="Apply filter"
                  buttonClass="w-full h-full ttext-base font-manrope font-semibold bg-purple-1500 text-white px-7 py-2.5 lg:py-2 lg:px-4 rounded-full hover:bg-purple-1800"
                  onClick={() => handleApplyFilter(selectedOption)}
                  isFullWidth
                />
              </div>
              <div className="sm:hidden">
                <CustomButton
                  text="Apply"
                  buttonClass="h-full text-base font-manrope font-semibold bg-purple-1500 w-full text-white sm:w-auto px-7 py-2.5 lg:py-2 lg:px-4 rounded-full hover:bg-purple-1800"
                  onClick={() => handleApplyFilter(selectedOption)}
                  isFullWidth
                />
              </div>
            </div>
          </section>
        </Popup>
      </article>
    </Layout>
  );
};

export default Webinars;

export const query = graphql`
  query PrismicWebinarPage {
    prismicWebinarPage {
      data {
        page_heading {
          text
          html
        }
        page_description {
          text
          html
        }
        meta_heading {
          text
        }
        meta_description {
          text
        }
        cta_heading {
          text
        }
        cta_description {
          text
        }
        page_subheading {
          text
        }
      }
    }
    allPrismicWebinarDetail {
      nodes {
        uid
        tags
        data {
          hide_date
          start_time
          video_time
          thumbnail {
            gatsbyImageData(
              width: 272
              height: 210
              sizes: "(min-width: 1280px) 273px, (min-width: 1040px) calc(50vw - 220px), (min-width: 640px) calc(50vw - 60px), calc(100vw - 34px)"
              placeholder: BLURRED
              layout: CONSTRAINED
            )
            url
          }
          page_heading {
            text
          }
          page_description {
            text
          }
          featured_webinar
          end_time
          body {
            ... on PrismicWebinarDetailDataBodySpeaker {
              id
              items {
                speaker_name {
                  text
                }
                speaker_image {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 160
                    height: 160
                    sizes: "(min-width: 1040px) 160px, (min-width: 640px) 144px, (min-width: 340px) 129px, calc(-55vw + 296px)"
                  )
                  url
                  alt
                  dimensions {
                    height
                    width
                  }
                }
                speaker_designation {
                  text
                }
                speaker_company {
                  text
                }
              }
              slice_type
            }
          }
        }
      }
      distinct(field: { tags: SELECT })
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicWebinarPage?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
