import React from "react";
import {
  CalendarIcon,
  FeaturedIcon,
  HeroCardVector,
  TimeIcon,
  UpcomingIcon,
} from "../../assets/icons/webinar";
import CustomButton from "../CustomButton";
import { formatCombineDate } from "../../utilities/FormatBlogDate";
import { checkFutureTime, pickSlice } from "../../utilities/helpers";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HeroCard = ({
  label,
  heading,
  date,
  time,
  description,
  cta_link,
  hide_date,
  speakers,
  mainClass,
}) => {
  const isFuture = checkFutureTime(time);
  const [_speakers] = pickSlice(speakers, "speaker");

  return (
    <section
      className={`flex justify-between lg:items-start h-full lg:gap-x-16 sm:gap-y-10 gap-y-5 flex-col lg:flex-row ${mainClass} relative overflow-hidden rounded-2.5xl lg:p-10 sm:p-8 py-8 px-3.5 text-white tracking-tighter_3`}
    >
      <div className="absolute z-0 hidden -translate-y-1/2 max-w-none xl:left-72 left-52 top-1/2 lg:block">
        <HeroCardVector />
      </div>
      <div className="absolute bg_hero_elipse lg:right-16 sm:right-32 right-8 lg:top-8 sm:top-14 top-14" />
      <section className="relative flex flex-col justify-center sm:max-w-xl">
        <div className="flex items-center sm:gap-x-2 gap-x-1">
          {isFuture ? <UpcomingIcon /> : <FeaturedIcon />}
          <div className="text-xs font-semibold uppercase tracking-widest_1 opacity-80 font-manrope">
            {label}
          </div>
        </div>

        <h4 className="line-clamp-4 font-semibold sm:text-3.25xl text-2.5xl leading-tight sm:mt-5 my-4 sm:mb-3.5 font-manrope tracking-tighter_3">
          {heading}
        </h4>
        {!hide_date && (
          <div className="flex sm:gap-x-2 gap-x-1.5 items-center">
            {date ? <CalendarIcon /> : <TimeIcon />}
            <div className="text-sm font-semibold sm:text-lg opacity-70 font-manrope">
              {formatCombineDate(time, date)} BST
            </div>
          </div>
        )}
        <div className="line-clamp-2 sm:text-lg text-sm lg:mt-5 sm:mt-2.5 sm:mb-6 my-4 opacity-70">
          {description}
        </div>
        <CustomButton
          link={cta_link}
          text={isFuture ? "Register now" : "Watch now"}
          buttonClass="text-base tracking-tighter_3 font-manrope text-[#1E0842] font-semibold px-4 py-2 rounded-full bg-white"
        />
      </section>

      <section className="flex lg:self-center lg:gap-6 gap-[18px] flex-shrink-0 max-w-[280px] sm:max-w-[346px] lg:max-w-[unset]">
        {_speakers?.items?.map((speaker, index) => {
          const image = getImage(speaker?.speaker_image);

          return (
            <div
              key={index}
              className="relative flex flex-col items-center text-center max-w-[131px] sm:max-w-[unset]"
            >
              <GatsbyImage
                image={image}
                alt={speaker?.speaker_name?.text || "img"}
                className="rounded-full lg:w-40 sm:w-36 w-[131px] lg:h-40 sm:h-36 h-[131px]"
                loading="eager"
              />

              <div className="mt-2 text-base font-semibold sm:text-xl sm:mt-5 max-w-20 sm:max-w-full">
                {speaker?.speaker_name?.text}
              </div>
              <div className="my-1 text-sm sm:text-base text-gray-2200">
                {speaker?.speaker_designation?.text}
              </div>
              <div className="text-sm font-medium sm:text-base">
                {speaker?.speaker_company?.text}
              </div>
            </div>
          );
        })}
      </section>
    </section>
  );
};

export default HeroCard;
